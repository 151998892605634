import React from 'react'
import FLCLightbulb from '../../components/FLCLightbulb'
import './styles.css'

// const content = sections[0].content
// console.log("Here's some infomation");
// console.log(sections[0].content[3].name);

const Test = () => {
	return (
		<div className="sandbox">
			<FLCLightbulb color="blue" />
		</div>
	)
}

export default Test
