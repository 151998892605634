// import React from 'react'
import Home from '../pages/Home';
import About from '../pages/About';
import TestPage from '../pages/TestPage';
import Courses from '../pages/Courses';
// import Summer from '../pages/Summer';
// import Login from '../pages/Login'
import Contact from '../pages/Contact';
import PrivacyAndTerms from '../pages/PrivacyAndTerms';
import PageNotFound from '../pages/PageNotFound';

// Model for all paths

const routes = [
  {
    id: 'home',
    name: 'Home',
    component: <Home />,
    path: '/',
  },
  {
    id: 'test',
    name: 'Test Page',
    component: <TestPage />,
    path: '/test-page',
  },
  {
    id: 'about',
    name: 'About',
    component: <About />,
    path: '/about',
  },
  {
    id: 'courses',
    name: 'Courses',
    component: <Courses />,
    path: '/courses',
  },
  // {
  //   id: 'summer',
  //   name: 'Summer',
  //   component: <Summer />,
  //   path: '/summer',
  // },
  {
    id: 'contact',
    name: 'Contact',
    component: <Contact />,
    path: '/contact',
  },
  {
    id: 'flip-login',
    name: 'FLIP Login',
    // component: FLIPLogin,
    path: 'https://flip.funlearningcompany.com/login/index.php',
  },
  // {
  // 	id: 'sign-up',
  // 	name: 'Sign Up',
  // 	// component: SignUp,
  // 	path: '/sign-up',
  // },
  {
    id: 'privacy-and-terms',
    name: 'Privacy and Terms',
    component: <PrivacyAndTerms />,
    path: '/privacy-and-terms',
  },
  {
    id: 'page-not-found',
    name: 'Page Not Found',
    component: <PageNotFound />,
    path: '/*',
  },
];

export default routes;
