import React, { useEffect } from 'react';
import SectionWithTitle from '../../components/SectionWithTitle';
import SectionWithTitleContent from '../../components/SectionWithTitleContent';
import IconWithDescription from '../../components/IconWithDescription';

import './styles.css';

import sections from './data';

const Courses = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {sections.map((section, i) => {
        return (
          <div key={'section' + i}>
            <SectionWithTitle
              title={section.title}
              children={
                <SectionWithTitleContent
                  content={section.content.map((content, i) => (
                    <IconWithDescription
                      key={'content' + i}
                      path={content.path}
                      name={content.name}
                      description={content.description}
                      link={content.link}
                    ></IconWithDescription>
                  ))}
                />
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default Courses;
