import React, { useEffect } from 'react';

import './styles.css';

function PrivacyAndTerms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='privacy-and-terms-container'>
      <section>
        <h2>Privacy Policy &amp; Terms of Service</h2>
        <p>
          BY ACCESSING ANY OF OUR WEBSITES, YOU AGREE TO THE TERMS OF THIS
          PRIVACY STATEMENT AND TERMS OF SERVICE. OUR PRIVACY POLICY AND TERMS
          OF SERVICE MAY CHANGE ACCORDING TO THE TERMS DESCRIBED BELOW AND ALL
          CHANGES ARE EFFECTIVE IMMEDIATELY. PLEASE READ THE ENTIRE STATEMENT
          BEFORE USING THIS WEBSITE OR PROVIDING ANY INFORMATION THROUGH ANY OF
          THE SITES LISTED BELOW.
        </p>
        <p>
          This page describes the ways in which “Fun Learning Company” and all
          of its divisions (collectively referred to here as “Fun Learning
          Company,” “FLC,” “FLIP,” “we,” “us” or “our”) handle information it
          obtains about individuals through the websites it operates, including:
        </p>

        <ul>
          <li>
            funlearningcompany.com (and all sub-domains of
            flip.funlearningcompany.com)
          </li>
        </ul>

        <p>
          We provide a variety of information, features, and functions (the
          “Services”) through this website.
        </p>
        <p>
          This page does not necessarily reflect the ways in which we collect
          and use information by means other than these websites. If you have
          questions about the ways in which we collect information by other
          means, please contact us using the contact information in this
          statement. The practices relating to persons with separate agreements
          with Fun Learning Company or its affiliates are separately covered in
          those agreements.
        </p>
        <p>
          The information we collect will depend on what you do when visiting
          our sites. EXCEPT AS STATED IN THIS STATEMENT, WE WILL NOT COLLECT
          PERSONALLY IDENTIFIABLE INFORMATION ABOUT YOU WHEN YOU VISIT OUR SITES
          UNLESS YOU CHOOSE TO PROVIDE SUCH INFORMATION TO US.{' '}
        </p>
        <p>
          You are free to visit our sites without providing personally
          identifiable information. However, if you would like to access our
          Services, in some cases you must first register or create an account
          or learning profile with Fun Learning Company. When you register or
          create an account or learning profile, we will request certain
          personal information. Requests can include but are not limited to your
          name, address, city, state, zip code, county, email address, age, home
          or other contact information, school contact information, gender, and
          parent/guardian contact information. In some cases, the sponsoring
          party funding your participation in the course (a “Sponsoring Party”)
          may provide this or other information about you to us. We may use this
          information to create an account or learning profile and issue you an
          access ID and password. If you are paying with a credit card, we also
          will collect your credit card number (employing industry-standard
          security and encryption technologies), billing information, and email
          address to process your payment.
        </p>
        <p>
          Personal information better enables us to provide you with content
          relevant to your interests and provides us with aggregate demographic
          data that Fun Learning Company uses to improve the services offered to
          users. Users may change their personal information at any time by
          using the provided interface once they are logged in and as often as
          necessary. If users are unable to log in and change their information
          themselves, they will be able to send an email to
          info@funlearningcompany.com or contact us at the address below for
          assistance.
        </p>
        <p>
          In addition to registration, Fun Learning Company may, from time to
          time, request personal information for purposes such as the provision
          of customer service, billing, network management, customer surveys,
          newsletter subscriptions, offers of related services, and other
          exchanges of information. Users may opt out of receiving future
          promotional mailings at any time. Please see below for User Options –
          Opt Out.
        </p>
        <p>
          Fun Learning Company gathers information about all users collectively,
          such as what areas users visit most frequently and what services
          users' access most often. Fun Learning Company automatically logs IP
          addresses, session sources, and other data which tracks users' access
          to our sites. We analyze these logs for sales and marketing purposes
          as well as system performance monitoring. These logs are analyzed for
          the overall, aggregate trends they reveal about our customers, not for
          the behaviors of individual users.
        </p>
        <p>
          The Fun Learning Company policy is to limit the collection of personal
          information from children in substantial agreement with the Children's
          Online Privacy and Protection Act (COPPA). Children ages 12 and under
          will not knowingly be allowed to register with Fun Learning Company or
          create an account without their parent's permission. Before children
          ages 12 and under may participate in any Services requiring
          registration (e.g., creating or enrolling in a course, chat rooms,
          message boards or other forums), the child's school must first obtain
          the parent's permission and that permission may allow us to collect
          and maintain the child's information. If at any time after a parent
          gives permission, the parent desires to review the child's personal
          information, request that it be deleted, or refuse to allow any
          further collection of the child's information, the parent may contact
          the school's representative for Fun Learning Company with such a
          request. If you have additional questions regarding your child's use
          of his or her account, you may contact the school's representative or
          Fun Learning Company. Please see below for our contact information.
        </p>
        <p>
          Fun Learning Company abides by{' '}
          <a href='https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule'>
            COPPA
          </a>{' '}
          and the regulations under it. You can obtain more information about
          COPPA from the{' '}
          <a href='https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions'>
            Federal Trade Commission
          </a>
          .
        </p>
        <p>
          Fun Learning Company may require logging in to one of our websites or
          systems in order to participate in a Service or course.
        </p>
        <p>
          Once logged in, your full name may be visible to other users (for
          instance in course areas such as discussion forums, email lists, web
          conferences, and other places where participant names appear). Please
          remember that information you disclose in these areas may be available
          to others within that course. Additionally, information from your user
          profile may be available to other users. Exercise caution when
          deciding to disclose your personal information.
        </p>
        <p>
          Fun Learning Company hosts, or provides links to, chat rooms, forums,
          and message boards. Our online instructors, instructor services, and
          student services staff monitor and respond to user email sent to them
          and monitor and participate with users in threaded discussions and
          other online forums. Fun Learning Company may monitor or edit the
          contents of a user's email or threaded discussions or other online
          forums as required for normal maintenance of any of our websites or in
          the good-faith belief that such action is necessary to:
        </p>

        <div>
          <ol>
            <li>
              Comply with the law or comply with the legal process served on Fun
              Learning Company.
            </li>
            <li>
              Protect and defend the rights or property of Fun Learning Company.
            </li>
            <li>
              Act to protect the personal safety of our guests or the public.
            </li>
          </ol>
        </div>
        <p>
          If you are a Fun Learning Company student, your grades in Fun Learning
          Company courses will be reported to your mentor and your school and/or
          sponsoring institution. Except as described further in this statement,
          the personal information you supply to Fun Learning Company will not
          be given, sold, rented, loaned, or otherwise disclosed to any third
          parties outside of Fun Learning Company, except when we have your
          permission or under special circumstances, such as when we believe in
          good faith that disclosure is reasonably necessary to:
        </p>

        <div>
          <ol>
            <li>Comply with the legal process.</li>
            <li>Enforce the Terms of Use.</li>
            <li>
              Respond to claims that any content on our sites violates the
              rights of third parties.
            </li>
            <li>
              Protect the rights, property, or personal safety of Fun Learning
              Company, its users, or the public.
            </li>
          </ol>
        </div>

        <p>
          Fun Learning Company may use aggregated personal information to
          determine the demographics of our users and to distribute statistics
          and general marketplace information about Fun Learning Company. We
          will use commercially reasonable efforts to ensure that these
          demographics do not identify any specific user.
        </p>
        <p>
          In accordance with{' '}
          <a href='http://www.legislature.mi.gov/(S(nsr0blp20xisnv4e4qr203hm))/mileg.aspx?page=getObject&objectName=mcl-Act-94-of-1979'>
            Michigan's State School Aid Act
          </a>
          , Fun Learning Company offers student courses as part of its effort to
          serve as a statewide laboratory and quality model of instruction for
          implementing virtual learning solutions. As such, it is possible that
          research will be conducted on a course in which students are enrolled
          or on students' online experience. Non-personally identifiable data
          will be shared with researchers for these purposes. If for any reason
          a student does not want to participate in research activities or does
          not want his or her data to be used for research purposes, please send
          an email at any time to info@funlearningcompany.com. In that email,
          please include the student's first and last name, the email address
          the student uses when signing into the Student Learning Interactive
          Portal (FLIP) as well as a sentence indicating that the student does
          not want to participate in research activities. Please use “Research”
          in the subject line of the email. A student's participation in
          research is voluntary and there is no penalty if a student does not
          want to participate.
        </p>
        <p>
          Fun Learning Company will abide by the student privacy guidelines set
          forth by the Family Educational Rights and Privacy Act (
          <a href='https://www2.ed.gov/policy/gen/guid/fpco/ferpa/index.html'>
            FERPA
          </a>
          ). The following persons have access to student records:
        </p>

        <div>
          <ul>
            <li>
              Fun Learning Company members, and contractor's. President and CEO,
              the Fun Learning Company administrative team, and professional
              staff of Fun Learning Company . Appropriate administrative support
              staff members and other professionals who have a legitimate
              educational or legal interest in student records as designated by
              the President and CEO.
            </li>
          </ul>
        </div>

        <p>
          Certain non-personal information regarding users is recorded by the
          standard operation of the Internet servers hosting our sites. This
          information may be used to provide visitors to our sites with an
          enhanced, online experience. Fun Learning Company may disclose
          aggregated user statistics, in order to describe the service to
          prospective partners, advertisers, and other third parties and for
          other lawful purposes.
        </p>
        <p>
          Fun Learning Company uses cookies to track information about visitors
          to our sites, such as the pages they visit and product information
          they view. We may do this through the use of cookies, flash cookies,
          or pixel tags. The information we collect helps us better manage the
          sites, provides ease of use of the sites for the user, and provides
          more effective marketing to the user.
        </p>

        <div>
          We collect traffic information on our website for analysis of the
          following:
          <ul>
            <li>
              Internet protocol (IP) addresses, browser type, internet service
              provider (ISP), referring/exit pages, platform type, date/time
              stamp, and the number of clicks.
            </li>
          </ul>
        </div>

        <p>
          We may use outside shipping or other outsourcing providers to process
          and ship orders or perform other functions. We require that these
          outsourcing providers not retain, share, store, or use personally
          identifiable information for any secondary purposes, except for backup
          and recovery operations. Although we use good faith efforts to ensure
          compliance by its outsourcing providers, we cannot, and will not, be
          responsible to users for misuse of personally identifiable information
          by such outsourcing providers.
        </p>
        <p>
          We may have agreements with other parties to provide specific
          services. Fun Learning Company may pass student personally
          identifiable information to integrated third-party tools used to
          enrich learner activity and experience. When we pass such personally
          identifiable information to integrated third-party tools, we do so
          with the minimum amount of information required by that tool. Our
          integrated vendors understand they have no rights to any student data
          we provide and have agreed to maintain the confidentiality of any such
          data that gets passed to them.
        </p>
        <p>
          We sometimes supplement the information we receive from you with other
          information we receive from third-party sources, such as credit card
          issuers or clearinghouses.
        </p>

        <h3>USER Opt-out Options</h3>

        <p>
          A user may opt-out to cease receiving future promotional mailings by
          contacting us at the email and, or address below. Fun Learning Company
          reserves the right to send all users non-promotional emails related to
          business practices and systems operations, such as outage
          notifications and security warnings, even if the user has opted out of
          promotional mailings.
        </p>
        <p>
          Visiting any of our websites and providing personal information
          sufficient to be able to email information to you will be sufficient
          to create an existing business relationship with you for purposes of
          complying with the CAN-SPAM Act of 2003 regarding unsolicited
          commercial email.
        </p>
        <p>
          We will use commercially reasonable efforts to make timely changes you
          may request regarding your personal information. Many such changes are
          accomplished using batch processing (i.e., collecting a number of
          similar change requests and making all such changes at once), so the
          changes may not be immediately effective. If you require an immediate
          change to your personally identifiable information and are unable to
          make such a change using the available site resources, please contact
          us using the information below.
        </p>

        <h3>Links to other Websites</h3>

        <p>
          You should be aware that when you are on our sites, you could be
          directed or provided with links to other sites that are beyond our
          control and have no affiliation or business relationship with Fun
          Learning Company. Fun Learning Company is not responsible for the
          content or information collection practices of these websites. Please
          note that these websites' privacy policies may substantially differ
          from this statement. We encourage you to review and understand the
          privacy practices of any website before providing to them any personal
          information.
        </p>
        <p>
          Our websites use security measures that we believe are reasonably
          designed to protect against the loss, misuse, and alteration of the
          information under our control. Personal information is stored in a
          restricted access, secure database, and only Fun Learning Company
          staff or other authorized personnel supporting your enrollment or
          Services are authorized to have access to that database. Fun Learning
          Company employs security measures and industry-standard encryption for
          all online purchases.
        </p>
        <p>
          If you have any concerns regarding the security of any Fun Learning
          Company system, please send an email to info@funlearningcompany.com,
          and we will reply to you within two business days.
        </p>

        <h3>FLC Privacy Policy Statement Changes</h3>

        <p>
          <b>
            If the Fun Learning Company changes this privacy statement, we will
            post those changes on this website and the revised privacy statement
            will be effective immediately upon posting. By using any of our
            websites or services, you agree to this change mechanism. If you do
            not agree to this change mechanism, please do not use our sites or
            services. Your continued use of our sites or services following the
            posting of changes to these terms will mean you accept those
            changes.
          </b>
        </p>
        <p>
          You are not permitted to associate any Fun Learning Company - provided
          account, service, or course with any adult material of any sort. This
          includes, but is not limited to, such things as nudity, any site,
          page, image or service, and any text, image or likeness suggesting
          sexual and or illegal acts of any sort. We do not permit any Fun
          Learning Company user account to store, use, contain, promote or
          display pornography, adult novelties, adult toys, XXX material,
          Gorean, bondage, BDSM, anything illegal, bigotry, racism, hatred,
          profanity, or any material that may be insulting to another person(s)
          or company, or depicts the exploitation of minors (children under 18
          years of age). No spamming is allowed, no harassing, threatening, or
          illegal activities are tolerated. Any user accounts found to be
          violating these service limitations will be terminated without warning
          or recourse at the sole discretion of Fun Learning Company.
        </p>

        <h3>In addition, we do not allow…</h3>

        <p>
          File Storage — Any User Account found to be storing files for use on
          other websites will be terminated without notice or recourse, at the
          sole discretion of Fun Learning Company.
        </p>
        <div>
          <ul>
            <li>
              User Accounts found to be storing files renamed, or modified in
              any way, to bypass Fun Learning Company file restrictions will be
              terminated without notice or recourse, at the sole discretion of
              Fun Learning Company. The only files that may be stored or
              uploaded to a Fun Learning Company server are those that directly
              pertain to the teaching, learning, or class activity for which the
              User Account has been granted access to a Fun Learning Company
              server or application.
            </li>
            <li>
              Copyrighted or Trademarked materials — NO copyrighted or
              trademarked materials may be stored on Fun Learning Company
              servers, unless the account owner is the sole legal owner of the
              Copyright or Trademark and can reasonably demonstrate Ownership or
              legal permission for use, on demand.
            </li>
          </ul>
        </div>
        <p>
          Material linked to User Accounts must be appropriate and be able to be
          legally viewed and used by all ages. User Accounts found to be in
          violation of any portion of this section can and will be terminated
          without notice or recourse at the sole discretion of Fun Learning
          Company. In such cases, where an account is terminated for service
          violations as detailed in this or the following two sections; no
          refunds or credit will be offered, and the User acknowledges that
          their account termination may result in course failure as well as
          other disciplinary action.
        </p>
        <p>
          <i>Fun Learning Company</i> servers may be used for lawful purposes
          only. Transmission, storage, or distribution of any information, data,
          or material in violation of any applicable law or regulation is
          prohibited. This includes, but is not limited to Copyrighted material,
          trademarks, trade secrets, or other intellectual property rights used
          without proper authorization; material that is obscene, defamatory,
          constitutes an illegal threat, or violates export control laws.
        </p>
        <p>
          Violations of system or network security are prohibited and may result
          in criminal and civil liability. Examples include but are not limited
          to the following: unauthorized access, use, probing, or scanning of
          systems security or authentication measures, data, or traffic;
          interference with service to any user, host, or network including,
          without limitation, mail bombing, flooding, deliberate attempts to
          overload a system, broadcast attacks; forging of any TCP-IP packet
          header or any part of the header information in an email or a
          newsgroup posting.
        </p>
        <h3>CONTACT</h3>
        <p>
          If you have any questions about this privacy statement or the privacy
          practices of this website, please contact us electronically at
          info@funlearningcompany.com or write to us:
        </p>

        <p>Fun Learning Company</p>
        <p>21341 Fairfield Dr.</p>
        <p>Macomb, MI 48044</p>

        <p>
          In the event that you are concerned about how data you have provided
          to Fun Learning Company or one of its divisions through our sites and
          services have been used, please contact us electronically at
          info@funlearningcompany.com or write to the postal address listed
          above.
        </p>
        <p>[Last Updated 8/20/2021]</p>
        <p>
          These Terms and Conditions apply to all customers purchasing courses
          from Fun Learning Company, Fun Learning Interactive Portal (FLIP). All
          customers must acknowledge and accept these Terms and Conditions
          before Fun Learning Company can supply the products/services requested
          through Fun Learning Company. Submission of any enrollment within the
          Fun Learning Interactive Portal (FLIP) shall indicate customer's full
          understanding and agreement to the prices, refund/credit policies and
          terms associated with said products/services.
        </p>
        <p>Customer further understands, acknowledges and accepts that:</p>

        <div>
          <ul>
            <li>
              Student(s) will not be granted access to the purchased course(s)
              by Fun Learning Company staff until either full payment by credit
              card, or a Purchase Order or Consortium payment method has been
              selected in the FLIP by eligible customers.
            </li>
            <li>
              Parents must pay by credit card. Student(s) will not be granted
              access to the course(s) until payment has been received and
              processed.
            </li>
          </ul>
        </div>

        <p>[Last Updated 8/20/2021]</p>
      </section>
    </div>
  );
}

export default PrivacyAndTerms;
