// import icon from "../images/employee_icons/"
import { Link } from 'react-router-dom'
import FLCLink from '../../components/FLCLink/FLCLink'
// import { employees } from "../assets/images/employee_icons/";

// const reqSvgs = require.context( "../assets/images/employee_icons/", true, /\.png$/ )
// const allSvgFilepaths = reqSvgs.keys()
// const imagePath = allSvgFilePaths[0]
// const image = reqSvgs( imagePath )

// console.log("Hello: " + "imagePath");

const sections = [
	{
		title: 'Who are we?',
		id: 'who',
		content: [
			{
				name: 'Kathy Joyce',
				path: '../assets/images/employee_icons/Kathy_Joyce.png',
				role: 'Owner & Founder',
				description:
					'Kathy makes sure everyone is thinking outside of the box and satisfied with their work and their progress.',
				alt: 'image of ',
			},
			{
				name: 'Tara Matuszek',
				path: '../assets/images/employee_icons/Tara_Matuszek.png',
				role: 'Co-Chief Executive Officer',
				description:
					'Tara upholds a progressive work environment, keeping employees genuinely fulfilled and not overworked.',
				alt: 'image of ',
			},
			{
				name: 'Bill Joyce',
				path: '../assets/images/employee_icons/Bill_Joyce.png',
				role: 'Co-Chief Executive Officer',
				description:
					'Bill consistently pushes us into new sectors of business, ever broadening our influence in e-learning.',
				alt: 'image of ',
			},
			{
				name: 'Josh Kirby',
				path: '../assets/images/employee_icons/Josh_Kirby.png',
				role: 'Chief Technology Officer',
				description:
					'Josh keeps us on the cutting edge of tech and innovation and maintains consistency across our business.',
				alt: 'image of ',
			},
			// {
			// 	name: "Emylye Laperriere",
			// 	path: "../assets/images/employee_icons/Emylye_Laperriere.png",
			// 	role: (
			// 		<>
			// 			<i>Director of Communication</i>
			// 		</>
			// 	),
			// 	// description: "This person does some cool work",
			// 	alt: "image of ",
			// },
			// {
			// 	name: "Jim Joyce",
			// 	path: "../assets/images/employee_icons/Jim_Joyce.png",
			// 	role: (
			// 		<>
			// 			<i>Chief Financial Officer</i>
			// 		</>
			// 	),
			// 	// description: "This person does some cool work",
			// 	alt: "image of ",
			// },
			// {
			// 	name: "Alyssa McBride",
			// 	path: "../assets/images/employee_icons/Alyssa_McBride.png",
			// 	role: (
			// 		<>
			// 			<i>Digital Marketing Specialist</i>
			// 		</>
			// 	),
			// 	// description: "This person does some cool work",
			// 	alt: "image of ",
			// },
			// {
			// 	name: "Tyler McBride",
			// 	path: "../assets/images/employee_icons/Tyler_McBride.png",
			// 	role: (
			// 		<>
			// 			<i>Head of Product</i>
			// 		</>
			// 	),
			// 	// description: "This person does some cool work",
			// 	alt: "image of ",
			// },
			// {
			// 	name: "Jaclyn Joyce",
			// 	path: "../assets/images/employee_icons/Emylye_Laperriere.png",
			// 	role: (
			// 		<>
			// 			<i>Customer Service Specialist</i>
			// 		</>
			// 	),
			// 	// description: "This person does some cool work",
			// 	alt: "image of ",
			// },
			// {
			// 	name: "Rob Kerr",
			// 	path: "../assets/images/employee_icons/Rob_Kerr.png",
			// 	role: (
			// 		<>
			// 			<i>Director of Communication</i>
			// 		</>
			// 	),
			// 	// description: "This person does some cool work",
			// 	alt: "image of ",
			// },
		],
	},
	{
		title: 'What do we do?',
		id: 'what',
		content: [
			{
				description: (
					<>
						We design and produce fun, hands-on STEAM classes for students in
						grades K-12, but there's really no age limit on what you'll gain
						from participating in our classes – parents and students alike! We
						use a variety of equipment and technology to enhance creativity,
						troubleshooting, and many other real-world skills.
					</>
				),
			},
			{
				tags: {
					piper: (
						<Link to="https://www.playpiper.com/products/piper-computer-kit-1">
							Piper computer
						</Link>
					),
					lego: (
						<Link to="https://education.lego.com/en-us/products/lego-mindstorms-education-ev3-core-set/5003400#lego-mindstorms-education-ev3">
							Piper computer
						</Link>
					),
					americanGirls: (
						<Link to="https://www.americangirl.com/shop/c/dolls">
							Piper computer
						</Link>
					),
				},
				description: (
					<>
						Depending on the class you take, you may find yourself coding with a
						<FLCLink
							link="https://www.playpiper.com/products/piper-computer-kit-1"
							text="Piper computer"
							target="_blank"
							rel="noreferrer"
							className="flc-link-inline"
						/>
						, building a
						<FLCLink
							link="https://education.lego.com/en-us/products/lego-mindstorms-education-ev3-core-set/5003400#lego-mindstorms-education-ev3"
							text="robot with LEGOs"
							target="_blank"
							rel="noreferrer"
							className="flc-link-inline"
						/>
						, or learning about the history and culture around an
						<FLCLink
							link="https://www.americangirl.com/shop/c/dolls"
							text="American Girl doll"
							target="_blank"
							rel="noreferrer"
							className="flc-link-inline"
						/>
						. Our classes come in many different varieties and styles, too, from
						one-time workshops to multi-year courses!
					</>
				),
			},
		],
	},
	{
		title: 'Why are we here?',
		id: 'why',
		content: [
			{
				name: 'CREATIVITY',
				path: '../assets/images/values_icons/Creativity.png',
				description:
					"Learning is an experience; it should be fulfilling, accessible, endless, and of course, fun! By providing individual access to high-quality equipment and content, we're enabling students to work at their own pace while exploring their own interests and creating original projects!",
				alt: 'The value of creativity',
			},
			{
				name: 'INTEGRITY',
				path: '../assets/images/values_icons/Integrity.png',
				description:
					"All of our students, parents, school administrators, and employees deserve a trustworthy and transparent environment – whether it's for work or play. We're all about wide-open channels of communication and crystal-clear expectations to ensure we're always on the same page with everyone we work with.",
				alt: 'The value of integrity',
			},
			{
				name: 'CURIOSITY',
				path: '../assets/images/values_icons/Curiosity.png',
				description:
					'Our curriculum is intended to be well-structured, thorough, and extensive, in hopes that even the most curious minds will never run out of interesting subjects to explore. Students of all ages should be enabled and encouraged to find and pursue their passions, as well as discover (or rediscover) the joys of learning!',
				alt: 'The value of curiosity',
			},
			{
				name: 'INCLUSION',
				path: '../assets/images/values_icons/Inclusion.png',
				description:
					"We strive to make our classes available, affordable, and accessible, and we're happy to mold our curriculum to fit specific needs of individual students or parents. Students who enroll in our classes join a large community of like-minded people, where they can share their accomplishments, develop new ideas, and ask for help from their peers.",
				alt: 'The value of inclusion',
			},
		],
	},
	{
		title: 'Where did we come from?',
		id: 'where',
		content: [
			{
				name: 'timeline',
				path: '../assets/images/informational/timeline.png',
				alt: "Image of the company's timeline.",
			},
		],
	},
	{
		title: 'How will we grow?',
		id: 'how',
		content: [
			{
				description: (
					<>
						There are plenty of goals we have for the future, but some of the
						ones we're most excited for are:
						<ul>
							<li>
								<div className="flc-list-bullet" />
								<p>
									Partnering with other companies that are on the cutting edge
									of virtual education.
								</p>
							</li>
							<li>
								<div className="flc-list-bullet" />
								<p>
									Creating in-house financial aid programs for students who
									can't afford to take our classes/camps.
								</p>
							</li>
							<li>
								<div className="flc-list-bullet" />
								<p>
									Continually improving our courses based on student feedback to
									be more fun and engaging.
								</p>
							</li>
							<li>
								<div className="flc-list-bullet" />
								<p>Expanding our virtual services across the world.</p>
							</li>
						</ul>
					</>
				),
			},
		],
	},
	{
		title: 'When can you take part?',
		id: 'when',
		content: [
			{
				description: (
					<>
						Now! Get started <Link to="/Courses">here</Link>.
					</>
				),
			},
		],
	},
	{
		title: 'Questions?',
		id: 'questions',
		content: [
			{
				description: (
					<>
						Check out our <Link to="/faqs">FAQ</Link> section.
					</>
				),
			},
		],
	},
]

export default sections
