import React, { useEffect } from 'react';

import FLCButton from '../../components/FLCButton';

import './styles.css';

import cards from './data';
import IconWithDescriptionCard from '../../components/IconWithDescriptionCard';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='home-container'>
      <div className='home-page-description'>
        <p className='description-large'>Welcome to Fun Learning Company</p>
      </div>
      <div className='home-cards-container'>
        {cards.map((card, i) => (
          <IconWithDescriptionCard
            card={card}
            key={i}
          >
            <div className='learn-more-container'>
              <FLCButton
                text='Learn more'
                link={card?.nav}
              />
            </div>
          </IconWithDescriptionCard>
        ))}
      </div>
      {/* <p className="description-small">
				We offer a variety of hands-on, engaging, educational experiences for
				students of all ages.
			</p>
			<p className="subtitle">
				You can learn to solder at our drone building summer camp, design a game
				in our virtual Build &amp; Program your own Computer class, or so much
				more!
			</p> */}
    </div>
  );
};

export default Home;
