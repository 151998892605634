import React from 'react';
// import onClickOutside from 'react-onclickoutside'

import './styles.css';

const Dropdown = ({
  title,
  id,
  type,
  required,
  value,
  placeholder,
  items = [],
  multiSelect = false,
  onChange,
}) => {
  return (
    <div
      id={id}
      className='input-field'
    >
      <div className='text-field-dropdown-title'>
        {title}
        {required ? <>&nbsp;*</> : ''}
      </div>
      <label className='input-field-short-title'>
        <select
          className='input-field-subject'
          value={value}
          onChange={onChange}
          required
        >
          <option
            key='0'
            value=''
            defaultValue
          >
            {placeholder}
          </option>
          {items.map((item, i) => (
            <option key={i}>{item.value}</option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default Dropdown;
