import React from 'react'

import './styles.css'

const SectionWithTitle = ({ title, children }) => {
	return (
		<section className="section-with-title">
			<h2>{title}</h2>
			{children}
		</section>
	)
}

export default SectionWithTitle
