// import FLCButton from '../../components/FLCButton';

const cards = [
  {
    name: 'CLASSES',
    path: '../assets/images/icons/lightbulb_brain.png',
    nav: '../courses',
    description:
      'Our courses in robotics, electronics, and technology will keep students engaged, curious, and excited to learn. These courses are build to sustain student learning for years to come.',
    alt: 'Classes',
  },
  // {
  //   name: 'SUMMER',
  //   path: '../assets/images/icons/summer_camps.png',
  //   nav: '../summer',
  //   description:
  //     'We team up with many kinds of organizations from YMCA camps and community centers to public schools and Montessori schools to bring fun, hands-on summer camps to students!',
  //   alt: 'Summer camps',
  // },
  {
    name: 'QUESTIONS',
    path: '../assets/images/icons/question_bubble.png',
    nav: '../contact',
    description:
      'We are always looking to provide the greatest possible experience for students, parents, and administrators. If you have any questions, comments, or feedback for us, let us know!',
    alt: 'Questions or comments',
  },
];

export default cards;
