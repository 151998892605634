// import items from '../../components/Footer/FooterData';

const info = '../images/icons/info_icon.png';

const fields = [
  {
    title: 'Full name',
    id: 'fullName',
    key: 1,
    name: 'fullName',
    type: 'text',
    required: true,
    path: info,
    description: (
      <>
        We need your first and last name to be able to address you properly. We
        like call people by their names.
      </>
    ),
    maxChars: 255,
    placeholder: 'Enter your full name here.',
    color: '#cc3333',
  },
  {
    title: 'Email address',
    id: 'email',
    key: 2,
    name: 'email',
    type: 'email',
    required: true,
    path: info,
    description: (
      <>
        We need your email address to be able to differentiate your message from
        the other messages we receive.
      </>
    ),
    maxChars: 255,
    placeholder: 'Enter your email here.',
    color: '#00cc33',
  },
  {
    title: 'Phone number',
    id: 'phone',
    key: 3,
    name: 'phone',
    type: 'tel',
    pattern: '[0-9]{10}',
    required: false,
    path: info,
    description: <>(numbers only; no dashes.)</>,
    maxChars: 13,
    placeholder: 'Enter your phone here.',
    color: '#00cc33',
  },
  {
    title: 'Subject',
    id: 'subject',
    key: 4,
    name: 'subject',
    type: 'button',
    pattern: 'Select a subject',
    required: true,
    path: info,
    description: (
      <>
        Having a subject helps us organize your message into the most
        appropriate category and allows us to get it to the right employee.
      </>
    ),
    maxChars: 100,
    placeholder: 'Select a subject',
    color: '#333399',
    items: [
      {
        value: 'Website',
      },
      {
        value: 'Classes',
      },
      {
        value: 'Summer camps',
      },
      {
        value: 'Payments',
      },
      {
        value: 'Other inquiry',
      },
    ],
  },
  {
    title: 'Message',
    id: 'message',
    key: 5,
    name: 'message',
    type: 'text',
    required: true,
    path: info,
    description: (
      <>
        We take these messages seriously. The more clear and concise you are
        when typing your inquiry, the more wholesome our response will be.
      </>
    ),
    maxChars: 1000,
    placeholder: 'Ask us or tell us anything here.',
    color: '#93278f',
  },
];

export default fields;
