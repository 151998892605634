import './styles.css'

const FLCLightbulb = ({ color }) => {
	return (
		<div className="flc-lightbulb-container">
			<div
				className="flc-lightbulb-case"
				style={{
					backgroundColor: color,
				}}
			>
				<div className="flc-lightbulb">
					<div
						className="flc-lightbulb-ring"
						style={{
							borderColor: color,
						}}
					></div>
				</div>
			</div>
		</div>
	)
}

export default FLCLightbulb
