import './styles.css'

const FLCLink = ({ link, text, className, target, rel }) => {
	return (
		<a href={link} className={className} target={target} rel={rel}>
			{text}
		</a>
	)
}

export default FLCLink
