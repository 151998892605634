import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import FLCButton from '../FLCButton'

import './styles.css'

import items from './data'

const Navbar = () => {
	const [isNavCollapsed, setIsNavCollapsed] = useState(false)
	// const [isActive, setIsActive] = useState(false)

	const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed)

	return (
		<div className="collapsible-nav" id={isNavCollapsed ? 'hidden' : ''}>
			<button
				className="collapsible-nav-btn flc-button"
				onClick={handleNavCollapse}
			>
				Menu
			</button>
			<nav className="navbar">
				{items.map(item =>
					item.name === 'FLIP Login' ? (
						<FLCButton key={'login'} link={item.link} text={item.name} />
					) : (
						<NavLink
							to={item.link}
							key={item.id}
							// className={'navbar-item navbar-item-link'}
							className={navData =>
								navData.isActive
									? 'active-navbar-item navbar-item navbar-item-link'
									: 'navbar-item navbar-item-link'
							}
							// style={() => 'active-navbar-item'}
							// activeClassName="active-navbar-item"
							onClick={handleNavCollapse}
						>
							{item.name}
						</NavLink>
					)
				)}
			</nav>
		</div>
	)
}

export default Navbar
