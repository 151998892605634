import React from 'react';

import FLCLink from '../FLCLink';

import './styles.css';

function IconWithDescription({ path, name, description, alt, link }) {
  return (
    <div className='icon-with-description'>
      <img
        src={path}
        alt={alt}
      />
      <p className='section-content-description'>
        <b>
          <i>{name}</i>
        </b>
        &emsp;&emsp;{description}
        {link != null ? (
          <FLCLink
            text='Learn more'
            link={link}
            className='flc-link-block'
          ></FLCLink>
        ) : (
          ''
        )}
      </p>
    </div>
  );
}

export default IconWithDescription;
