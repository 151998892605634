// import { Link } from 'react-router-dom'
const sections = [
  {
    title: 'Here are some of the courses we offer!',
    content: [
      {
        name: 'Build & Program Your Own Computer',
        ages: '',
        path: '../assets/images/courses/build_and_program_your_own_computer.png',
        description:
          'Students learn how to control electronic components, code with real-world programming languages, and implement their skills in Minecraft, using their very own computers!',
        alt: 'The value of ',
        // link: 'https://flip.funlearningcompany.com/course/view.php?id=220',
        link: 'https://funlearningcompany.com/contact', // temporary
      },
      {
        name: 'Electronics',
        ages: '',
        path: '../assets/images/courses/electronics.png',
        description:
          'Students learn the fundamentals of electricity and electronic components with hands-on projects to build real-world skills in taking apart, putting together, and fixing simple to more complex circuits.',
        alt: 'The value of ',
        // link: 'https://flip.funlearningcompany.com/course/view.php?id=221',
        link: 'https://funlearningcompany.com/contact', // temporary
      },
      {
        name: 'Intro to Robotics',
        ages: '',
        path: '../assets/images/courses/intro_to_robotics.png',
        description:
          'Students learn the basics of building, problem-solving, and programming through immersive work on engineering challenges using a survey of different robots.',
        alt: 'The value of ',
        // link: 'https://flip.funlearningcompany.com/course/view.php?id=222',
        link: 'https://funlearningcompany.com/contact', // temporary
      },
      {
        name: 'Jr. Robotics',
        ages: '',
        path: '../assets/images/courses/jr_robotics.png',
        description:
          'Students learn the basics of building and programming with a variety of robots that will teach beginner geometry, basic coding with colored markers, and some concepts of simple machines (requiring little reading).',
        alt: 'The value of ',
        // link: 'https://flip.funlearningcompany.com/course/view.php?id=223',
        link: 'https://funlearningcompany.com/contact', // temporary
      },
      {
        name: 'Puzzles, Logic, & Games',
        ages: '',
        path: '../assets/images/courses/puzzles_logic_and_games.png',
        description:
          'Students learn strategy, logic, critical thinking, and problem-solving through games! This course introduces a variety of topics in graph theory, logical deduction, pattern recognition, and theory of mind using games like Set, Clue, Bananagrams, Catan, Mastermind, and more!',
        alt: 'The value of ',
        // link: 'https://flip.funlearningcompany.com/course/view.php?id=224',
        link: 'https://funlearningcompany.com/contact', // temporary
      },
    ],
  },
];

export default sections;
