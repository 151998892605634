import React from 'react';

import './styles.css';

const IconWithDescriptionCard = ({
  card: { path, description, alt },
  children,
}) => {
  return (
    <div className='icon-with-description-card-container'>
      <div className='icon-with-description-card'>
        <div className='icon-with-description-icon-container'>
          <img
            className='icon-with-description-icon'
            src={path}
            alt={alt}
          />
        </div>
        <div className='icon-with-description-description-container'>
          <p className='icon-with-description-description'>{description}</p>
        </div>
        <div className='icon-with-description-children-container'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default IconWithDescriptionCard;
