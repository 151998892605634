// import React from 'react'

// Model for navbar menu items

const items = [
  // {
  //   name: 'Home',
  //   link: '/',
  // },
  // {
  // 	id: 'test',
  // 	name: 'Test Page',
  // 	link: '/test-page',
  // },
  {
    id: 1,
    name: 'About',
    link: '/about',
  },
  {
    id: 2,
    name: 'Courses',
    link: '/courses',
  },
  // {
  //   id: 3,
  //   name: 'Summer',
  //   link: '/summer',
  // },
  {
    id: 4,
    name: 'Contact',
    link: '/contact',
  },
  {
    id: 5,
    name: 'FLIP Login',
    link: 'https://flip.funlearningcompany.com/login/index.php',
  },
  // {
  //   id: 6,
  //   name: 'Sign up',
  //   link: '/signup',
  // },
];

export default items;
