import React, { useEffect, useState } from 'react';
import axios from 'axios';

import TextFieldShort from '../../components/TextFieldShort';
import TextFieldLong from '../../components/TextFieldLong';
import Dropdown from '../../components/Dropdown';

import './styles.css';

import fields from './data';

const Contact = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('NA');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);
  const [confirmation, setConfirmation] = useState('');

  const handleSubmit = e => {
    const abortController = new AbortController();
    e.preventDefault();
    console.log('Clean up 1');
    axios
      .post('/api/form', {
        fullName,
        email,
        phone,
        subject,
        message,
        sent,
        confirmation,
        signal: abortController.signal,
      })
      .then(res => {
        if (res && !res.ok) {
          throw Error('Could not post to the specified endpoint.');
        }
      })
      .then(
        setFullName(''),
        setEmail(''),
        setPhone(''),
        setSubject('NA'),
        setMessage(''),
        setSent(true),
        setError(null),
        setConfirmation('Your message has been sent!')
      )
      .catch(err => {
        if (err.name === 'AbortError') {
          console.log('post aborted');
        } else {
          setError(err.message);
          setSent(false);
        }
      });
    // console.log('Clean up 2');
    return abortController.abort();
  };

  // const sendMessage = () => {
  // 	this.recaptcha.execute()
  // }

  // const onResolve = () => {
  // 	this.setState({ sent: true })
  // 	console.log(this.state)
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // for smoothly scrolling
    });
  }, [sent, error]);

  return (
    <div className='page-container'>
      <div className='contact-page-title'>
        <h1 className='description-large'>Contact Us</h1>
        <h2>Enter your information here:</h2>
      </div>
      {sent && !error && (
        <div className='confirmation-message-container'>
          <div className='confirmation-message'>{confirmation}</div>
        </div>
      )}
      {((sent && error) || error) && (
        <div className='confirmation-message-container'>
          <div
            className='confirmation-message'
            style={{ backgroundColor: '#ff7777' }}
          >
            {error}
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className='contact-page-fields'>
          {fields.map(field => {
            switch (field.id) {
              case 'fullName':
                return (
                  <TextFieldShort
                    title={field.title}
                    id={field.id}
                    type={field.type}
                    pattern={field.pattern}
                    required={field.required}
                    path={field.path}
                    maxChars={field.maxChars}
                    placeholder={field.placeholder}
                    color={field.color}
                    key={field.id}
                    value={fullName}
                    onChange={e => setFullName(e.target.value)}
                  />
                );

              case 'email':
                return (
                  <TextFieldShort
                    title={field.title}
                    id={field.id}
                    type={field.type}
                    pattern={field.pattern}
                    required={field.required}
                    path={field.path}
                    maxChars={field.maxChars}
                    placeholder={field.placeholder}
                    color={field.color}
                    key={field.id}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                );

              case 'phone':
                return (
                  <TextFieldShort
                    title={field.title}
                    id={field.id}
                    type={field.type}
                    pattern={field.pattern}
                    required={field.required}
                    path={field.path}
                    description={field.description}
                    maxChars={field.maxChars}
                    placeholder={field.placeholder}
                    color={field.color}
                    key={field.id}
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                );

              case 'subject':
                return (
                  <Dropdown
                    title={field.title}
                    id={field.id}
                    type={field.type}
                    required={field.required}
                    placeholder={field.placeholder}
                    items={field.items}
                    key={field.id}
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                  />
                );

              case 'message':
                return (
                  <TextFieldLong
                    title={field.title}
                    id={field.id}
                    type={field.type}
                    required={field.required}
                    path={field.path}
                    description={field.description}
                    maxChars={field.maxChars}
                    placeholder={field.placeholder}
                    color={field.color}
                    key={field.id}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                  />
                );
              default:
                return (
                  <TextFieldShort
                    title={field.title}
                    id={field.id}
                    type={field.type}
                    pattern={field.pattern}
                    required={field.required}
                    path={field.path}
                    maxChars={field.maxChars}
                    placeholder={field.placeholder}
                    color={field.color}
                    key={field.id}
                    // value={value}
                  />
                );
            }
          })}
        </div>
        <button
          className='flc-button'
          type='submit'
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Contact;
