import React from 'react'

import './styles.css'

const TextFieldLong = ({
	title,
	id,
	type,
	required,
	value,
	path,
	description,
	maxChars,
	placeholder,
	color,
	onChange,
}) => {
	return (
		<div id={id} className="text-field">
			<div className="text-field-long-title">
				<label>
					{title}
					{required ? <>&nbsp;*</> : ''}
				</label>
				{/* <img src={path} /> */}
			</div>
			<textarea
				type={type}
				placeholder={placeholder}
				maxLength={maxChars}
				required={required ? true : false}
				value={value}
				onChange={onChange}
			/>
		</div>
	)
}

export default TextFieldLong
