import React from 'react'
import { NavLink } from 'react-router-dom'

import AnimatedGradientLine from '../AnimatedGradientLine'

import './styles.css'

import items from './data'

const Footer = () => {
	return (
		<div className="footer-container">
			<AnimatedGradientLine />
			<div className="footer-items">
				{items.map(item => {
					return (
						<div key={item.categoryId} className="footer-categories">
							<div className="footer-categories-outline"></div>
							<div className="footer-categories-gradient"></div>
							<h3>{item.category}</h3>
							{item.pages.map(page =>
								page.external ? (
									<a href={page.link} key={page.id}>
										{page.title}
									</a>
								) : (
									<NavLink key={page.id} to={page.link}>
										{page.title}
									</NavLink>
								)
							)}
						</div>
					)
				})}
			</div>
		</div>
	)
}
export default Footer
