import React from 'react'

import './styles.css'

function IconWithTitle({ key, name, path, role, description }) {
	return (
		<div key={key} className="icon-with-title">
			<div className="icon-decorative-border"></div>
			<div className="icon-decorative-gradient"></div>
			<div className="icon-container">
				<img src={path} alt={'image of ' + name + ' the ' + role} />
				<div className="icon-description">
					<h3>{name}</h3>
					<h4>{role}</h4>
					<p>{description}</p>
				</div>
			</div>
		</div>
	)
}

export default IconWithTitle
