import React from 'react'

import { NavLink } from 'react-router-dom'

import './styles.css'

// const content = sections[0].content
// console.log("Here's some infomation");
// console.log(sections[0].content[3].name);

const PageNotFound = () => {
	return (
		<div className="not-found">
			<h1>Page Not Found</h1>
			<h4>
				<NavLink to="/">Go home</NavLink>
			</h4>
			<h4>
				<a href="https://flip.funlearningcompany.com/login/index.php">Login</a>
			</h4>
		</div>
	)
}

export default PageNotFound
