import './styles.css'

const FLCButton = ({ link, text }) => {
	return (
		<a href={link} className="flc-button">
			{text}
		</a>
	)
}

export default FLCButton
