const items = [
	{
		categoryId: 1,
		category: 'Our Company',
		pages: [
			// {
			// 	title: 'Testimonials',
			// 	link: '/testimonials',
			// 	id: 'testimonials',
			// },
			// {
			// 	title: 'Our Mission',
			// 	link: '/about',
			// 	id: 'mission',
			// },
			{
				title: 'About Us',
				link: '/about',
				id: 'about',
			},
			{
				title: 'Privacy and Terms',
				link: '/privacy-and-terms',
				id: 'privacy-and-terms',
			},
		],
	},
	{
		categoryId: 2,
		category: 'Our Services',
		pages: [
			{
				title: 'Course Catalog',
				link: '/courses',
				id: 'courses',
			},
			// {
			// 	title: 'Summer Camps',
			// 	link: '/summer',
			// 	id: 'summer',
			// },
			{
				title: 'Fun Learning Interactive Portal',
				link: 'https://flip.funlearningcompany.com',
				id: 'flip',
				external: true,
			},
			// {
			// 	title: 'Instructor Training',
			// 	link: '/training',
			// 	id: 'training',
			// },
		],
	},
	{
		categoryId: 3,
		category: 'Contact Us',
		pages: [
			{
				title: 'Inquiries and Support',
				link: '/contact',
				id: 'contact',
			},
			// {
			// 	title: 'Technical Support',
			// 	link: '/support',
			// 	id: 'support',
			// },
			// {
			// 	title: 'FAQs',
			// 	link: '/faqs',
			// 	id: 'faqs',
			// },
			// {
			// 	title: 'Get Involved',
			// 	link: '/participate',
			// 	id: 'participate',
			// },
		],
	},
	{
		categoryId: 4,
		category: 'Social Media',
		pages: [
			{
				title: 'Facebook',
				link: 'https://www.facebook.com/FunLearningCompany',
				id: 'facebook.com',
				image: '',
				external: true,
			},
			{
				title: 'YouTube',
				link: 'https://www.youtube.com/channel/UCpTByq13oBx-zKJfI_Ns4Tw/featured',
				id: 'youtube',
				image: '',
				external: true,
			},
			{
				title: 'LinkedIn',
				link: 'https://www.linkedin.com/company/fun-learning-company',
				id: 'linkedin',
				image: '',
				external: true,
			},
			{
				title: 'Instagram',
				link: 'https://www.instagram.com/funlearningcompany/',
				id: 'instagram',
				image: '',
				external: true,
			},
		],
	},
]

export default items
