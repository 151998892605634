import React, { useEffect } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'

import './App.css'
import './Mobile.css'

import routeData from './models/RouteData'

const App = () => {
	useEffect(() => {
		window.onbeforeunload = () => {
			window.scrollTo(0, 0)
		}
	}, [])

	const routeComponents = routeData.map(({ id, component, path }) => (
		<Route path={path} element={component} key={id} />
	))

	return (
		<Router>
			<div className="App">
				<Header />
				<Routes>{routeComponents}</Routes>
				<Footer />
			</div>
		</Router>
	)
}

export default App
