import React, { useEffect } from 'react';

import SectionWithTitle from '../../components/SectionWithTitle';
import SectionWithTitleContent from '../../components/SectionWithTitleContent';
import IconWithTitle from '../../components/IconWithTitle';
import IconWithDescription from '../../components/IconWithDescription';
// import IconInfoCard from '../../components/IconInfoCard';

import './styles.css';

import sections from './data';

// const content = sections[0].content

const About = () => {
  //every load
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  //only runs on first load
  // useEffect(() => {
  // 	fetch(sections)
  // 	.then(res => res.json()
  // 	.then(date => {})))
  // }, [])

  return (
    <div className='page-container'>
      <div>
        <h1 className='description-large'>We are Fun Learning Company</h1>
        <h2>You can call us FLC. Whatever's easier.</h2>
      </div>

      {sections &&
        sections.map((section, i) => {
          // console.log(section.title)
          switch (section.title) {
            case 'Who are we?':
              return (
                <div
                  key={'section' + i}
                  id={section.id}
                >
                  <SectionWithTitle
                    title={section.title}
                    children={
                      <SectionWithTitleContent
                        content={section.content.map((content, j) => (
                          <div key={section.id + j}>
                            <IconWithTitle
                              path={content.path}
                              name={content.name}
                              role={content.role}
                              description={content.description}
                            />
                          </div>
                        ))}
                      />
                    }
                  />
                </div>
              );

            case 'Why are we here?':
              return (
                <div
                  key={'section' + i}
                  id={section.id}
                >
                  <SectionWithTitle
                    title={section.title}
                    children={
                      <SectionWithTitleContent
                        content={section.content.map((content, j) => (
                          <div key={section.id + j}>
                            <IconWithDescription
                              path={content.path}
                              name={content.name}
                              description={content.description}
                            />
                          </div>
                        ))}
                      />
                    }
                  />
                </div>
              );
            case 'Where did we come from?':
              return (
                <div
                  key={'section' + i}
                  id={section.id}
                >
                  {/* {console.log('test')} */}
                  <SectionWithTitle
                    title={section.title}
                    children={
                      <SectionWithTitleContent
                        content={section.content.map((content, j) => (
                          <img
                            key={section.id + i}
                            id={content?.name}
                            src={content.path}
                            alt={content.alt}
                          />
                        ))}
                      />
                    }
                  />
                </div>
              );

            // case 'How will we grow?':
            //   return (
            //     <div>
            //       {/* {console.log('test')} */}
            //       <SectionWithTitle title={section.title} children = {
            //         <SectionWithTitleContent content={
            //           section.content.map((content) =>
            //           <p className='section-content-description'>{content.description}</p>)}
            //         />
            //       } />
            //     </div>
            //   )

            // case 'When can you take part?':
            //   return (
            //     <div>
            //       {/* {console.log('test')} */}
            //       <SectionWithTitle title={section.title} children = {
            //         <SectionWithTitleContent content={
            //           section.content.map((content) =>
            //           <p className='section-content-description'>{content.description}</p>)}
            //         />
            //       } />
            //     </div>
            //   )

            // case 'Questions?':
            //   return (
            //     <div>
            //       {/* {console.log('test')} */}
            //       <SectionWithTitle title={section.title} children = {
            //         <SectionWithTitleContent content={
            //           section.content.map((content) =>
            //           <p className='section-content-description'>{content.description}</p>)}
            //         />
            //       } />
            //     </div>
            //   )
            default:
              return (
                <div
                  key={'section' + i}
                  id={section.id}
                >
                  {/* {console.log('test')} */}
                  <SectionWithTitle
                    title={section.title}
                    children={
                      <SectionWithTitleContent
                        content={section.content.map((content, j) => (
                          <div
                            key={section.id + j}
                            className='section-content-description'
                          >
                            {content.description}
                          </div>
                        ))}
                      />
                    }
                  />
                </div>
              );
          }
        })}
    </div>
  );
};

export default About;
