import React from 'react'
import { NavLink } from 'react-router-dom'

import Navbar from '../Navbar'

import './styles.scss'

import logo from '../../assets/images/logo.svg'

const Header = () => {
	return (
		<div className="header-spacer">
			<header className="App-header">
				<NavLink to="/">
					<img src={logo} alt="company logo" className="navbar-logo" />
				</NavLink>
				<Navbar />
				<div className="white-fill"></div>
				<div className="gradient-fill"></div>
			</header>
		</div>
	)
}

export default Header
